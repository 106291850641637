<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        ref="btnItinerary"
        @click="goItinerary"
        :disabled="$route.name === 'Itinerary'"
        icon
        color="primary"
        height="50px"
        width="50px"
        :style="style"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>far fa-route</v-icon>
      </v-btn>
    </template>
    <span>Nouvel itinéraire</span>
  </v-tooltip>

</template>

<script>
export default {
  name: 'ItineraryBtn',

  methods: {
    goItinerary() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.$router.push({name: "Itinerary", query: {...this.$route.query, fromLabel: "Ma position", from: `${position.coords.latitude},${position.coords.longitude}`}});
      }, (error) => {
        this.$router.push({name: "Itinerary"});
      });

    },

  },

  computed: {
    isMobile() {
      return this.$store.getters['isMobile'];
    },

    style() {
      if (this.isMobile)
        return 'position: fixed !important; bottom: 130px; right: 10px;background-color: white';
      return 'position: absolute; bottom: 170px; right: 20px;background-color: white';
    }
  }
};
</script>

<style scoped>

</style>